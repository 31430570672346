import React, { Suspense, lazy, Component } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import "./Assets/Styles/styles.scss";
import ScrollToTop from "./ScrollToTop";
import GeoLocation from "./Containers/GeoLocation";
import RealEstate from "./Containers/RealEstate";
import Referrals from "./Containers/Referrals/";
import Promotion from "./Common/Promotion";
import Underpining from "./Containers/Underpinning/";

import { ChatBox } from './Common/ChatBox';

const Header = lazy(() => import("./Common/header"));
const Footer = lazy(() => import("./Common/footer"));
const Estimate = lazy(() => import("./Containers/Estimate"));
const Home = lazy(() => import("./Containers/Home"));
const TheAGMDifference = lazy(() => import("./Containers/TheAGMDifference"));
const OurClients = lazy(() => import("./Containers/OurClients"));
// const OurNewsBlog = lazy(() => import("./Containers/OurNewsBlog"));
const OurWork = lazy(() => import("./Containers/OurWork"));
const OurWorkMobile = lazy(() => import("./Containers/OurWorkMobile"));
const ThankYou = lazy(() => import("./Containers/ThankYou"));
const FinanceIt = lazy(() => import("./Containers/FinanceIt"));
// const FinishedBasements = lazy(() => import("./Containers/FinishedBasements"));
const Blog = lazy(() => import("./Containers/Blog"));
const SingleBlog = lazy(() => import("./Containers/SingleBlog"));
const OurTeam = lazy(() => import("./Containers/OurTeam"));
const ContactUs = lazy(() => import("./Containers/ContactUs"));
// const Vote = lazy(() => import("./Containers/Vote"));
// const EmployeeOfTheMonth = lazy(() =>
//   import("./Containers/EmployeeOfTheMonth")
// );
// const TradeOfTheMonth = lazy(() => import("./Containers/TradeOfTheMonth"));
// const Realtors = lazy(() => import("./Containers/Realtors"));
const Catalogue = lazy(() => import("./Containers/Catalogue"));
const Financing = lazy(() => import("./Containers/Financing"));
const AboutUs = lazy(() => import("./Containers/AboutUs"));
const Reviews = lazy(() => import("./Containers/Reviews"));
const AgmBBQ2016 = lazy(() => import("./Containers/AgmBBQ2016"));
const AgmBBQ2017 = lazy(() => import("./Containers/AgmBBQ2017"));
const RecentProject = lazy(() => import("./Containers/RecentProject"));
const ChristmasParty2016 = lazy(() =>
  import("./Containers/ChristmasParty2016")
);
const AgmOfficeChristmas2016 = lazy(() =>
  import("./Containers/AgmChristmasInOffice")
);
const BasementRenovationInBrampton = lazy(() =>
  import("./Containers/BasementRenovationInBrampton")
);
const BasementRenovationsInVaughan = lazy(() =>
  import("./Containers/BasementRenovationsInVaughan")
);
const BasementRenovationInTheDurhamRegion = lazy(() =>
  import("./Containers/BasementRenovationInTheDurhamRegion")
);
const BasementRenovationInRichmondHill = lazy(() =>
  import("./Containers/BasementRenovationInRichmondHill")
);
const BasementRenovationsInHamilton = lazy(() =>
  import("./Containers/BasementRenovationsInHamilton")
);
const BasementRenovationsInMarkham = lazy(() =>
  import("./Containers/BasementRenovationsInMarkham")
);
const BasementRenovationsInMississauga = lazy(() =>
  import("./Containers/BasementRenovationsInMississauga")
);
const BasementRenovationsInOakville = lazy(() =>
  import("./Containers/BasementRenovationsInOakville")
);
const BasementRenovationsInToronto = lazy(() =>
  import("./Containers/BasementRenovationsInToronto")
);
const BasementRenovationServices = lazy(() =>
  import("./Containers/BasementRenovationServices")
);
const BasementBathroomRenovation = lazy(() =>
  import("./Containers/BasementBathroomRenovation")
);
const BasementApartmentRenovation = lazy(() =>
  import("./Containers/BasementApartmentRenovation")
);
const BasementWorkoutRoom = lazy(() =>
  import("./Containers/BasementWorkoutRoom")
);
const BasementFamilyRoomRenovation = lazy(() =>
  import("./Containers/BasementFamilyRoomRenovation")
);
const BasementSaunasRenovation = lazy(() =>
  import("./Containers/BasementSaunasRenovation")
);
const BasementOfficeRenovation = lazy(() =>
  import("./Containers/BasementOfficeRenovation")
);
const BasementBedroomRenovation = lazy(() =>
  import("./Containers/BasementBedroomRenovation")
);
const BasementInlawSuiteRenovation = lazy(() =>
  import("./Containers/BasementInlawSuiteRenovation")
);
const BasementRecRoomRenovation = lazy(() =>
  import("./Containers/BasementRecRoomRenovation")
);
const BasementTheaterRoomRenovation = lazy(() =>
  import("./Containers/BasementTheaterRoomRenovation")
);
const BasementFireplaceRenovation = lazy(() =>
  import("./Containers/BasementFireplaceRenovation")
);
const BasementBarRenovation = lazy(() =>
  import("./Containers/BasementBarRenovation")
);
const BasementKitchenRenovation = lazy(() =>
  import("./Containers/BasementKitchenRenovation")
);
const CallCenter = lazy(() => import("./Containers/CallCenter"));
const CallCenterBrandon = lazy(() => import("./Containers/BrandonCallCenter"));
const Dordana = lazy(() => import("./Containers/Dordana"));
const Garth = lazy(() => import("./Containers/Garth"));
const Areas = lazy(() => import("./Containers/Areas"));
const Careers = lazy(() => import("./Containers/Careers"));
const CareersAdvanced = lazy(() => import("./Containers/CareersAdvanced"));
const WhyUs = lazy(() => import("./Containers/WhyUs"));
const WhyAGM = lazy(() => import("./Containers/WhyAgm/"));
const JobPostings = lazy(() => import("./Containers/JobPostings"));
const CustomerServiceAssociate = lazy(() =>
  import("./Containers/CustomerServiceAssociate")
);
const DoorToDoor = lazy(() => import("./Containers/DoorToDoor"));
const FieldSaleRepresentative = lazy(() =>
  import("./Containers/FieldSaleRepresentative")
);
const FrontDesk = lazy(() => import("./Containers/FrontDesk"));
const IncomingCallToCRM = lazy(() => import("./Containers/IncomingCallToCRM"));
const InteriorFinishing = lazy(() => import("./Containers/InteriorFinishing"));
const PrivacyPolicy = lazy(() => import("./Containers/PrivacyPolicy"));
const Refer = lazy(() => import("./Containers/Refer"));
const Staff = lazy(() => import("./Containers/Staff"));
const AgmChristmasParty2019 = lazy(() =>
  import("./Containers/AgmChristmasParty2019")
);
const Warranty = lazy(() => import("./Containers/Warranty"));
const WarrantyPayment = lazy(() => import("./Containers/WarrantyPayment"));
const WarrantyAdvanced = lazy(() => import("./Containers/WarrantyAdvanced"));
const BasementForm = lazy(() => import("./Containers/BasementForm"));
const WarrantyClaimForm = lazy(() => import("./Containers/WarrantyClaimForm"));
const PaymentReceipt = lazy(() => import("./Containers/PaymentReceipt"));
const BathroomEstimate = lazy(() => import("./Containers/BathroomEstimate"));
const Visualizer = lazy(() => import("./Containers/Visualizer"));

window.$json = null;

function resizeResumatorIframe(height, nojump) {
  // console.log("iframe height is " + height);
  if (nojump == 0) {
    window.scrollTo(0, 0);
  }
  document.getElementById("resumator-job-frame").height = parseInt(height) + 20;
}

function assignABGroup() {
  let group = localStorage.getItem("ABTGroup");

  if (!group) {
      group = Math.random() < 0.5 ? "A" : "B";
      localStorage.setItem("ABTGroup", group);
  }

  return group;
}
assignABGroup();

function RedirectWithStatus({ from, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.status = status;
        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      jsonData: window.$json,
    };
    this.updateStateJson = this.updateStateJson.bind(this);

  }
  updateStateJson = (json) => {
    // console.log("updateStateJson called from App.js");
    this.setState({ jsonData: json });
  };

  componentDidMount() {

    // const script = document.createElement("script");
    // script.src = "//cdn.rlets.com/capture_configs/c99/8ba/2b9/23547e78c6b4ea10d979c2e.js";
    // script.async = true;
    // document.body.appendChild(script);
  }

  render() {
    return (
      <BrowserRouter>
        <Helmet>
          <title>AGM Basements | Basement Renovations</title>
          <meta
            name="description"
            content="AGM Basements has a 4.5-star rating with over 200 Google Reviews as GTA’s top-rated basement renovations we provide 100% customer satisfaction."
          />
          <meta
            name="keywords"
            content="basement renovations toronto, AGM basements, basement renovations toronto costs, finishing a basement cost estimator, finished basements, finished basement costs, basement finishing financing, AMG design, high qualty basements toronto, basement renovations vaughan, basement renovations in GTA, basement finish, steps to finishing a basement,"
          />
          <meta
            name="robots"
            content="noindex"
          />
        </Helmet>
        <ScrollToTop>
          <Suspense fallback={<div></div>}>
            <Header data={this.state.jsonData} />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="*" render={() => <Redirect to="/" />} />

              </Switch>

          </Suspense>
        </ScrollToTop>

      </BrowserRouter>
    );
  }
}

export default App;
